import { Record, Union } from "../../.fable/fable-library.3.2.9/Types.js";
import { PagingResult$1$reflection, AcceptCustomerType$reflection, Country$reflection, NewCustomerFormState$reflection, CustomerStatus$reflection, CustomerSortFields$reflection, Customer$reflection } from "../../Shared/Shared.js";
import { class_type, array_type, uint8_type, tuple_type, string_type, option_type, list_type, record_type, bool_type, union_type, enum_type, int32_type } from "../../.fable/fable-library.3.2.9/Reflection.js";
import { Types_Msg$reflection, Types_State$reflection } from "../Common/Paging.js";
import { Types_Msg$1$reflection, Types_State$1$reflection } from "../Common/Sorting.js";
import { SelfMessage$1$reflection, State$reflection as State$reflection_1 } from "../../.fable/Thoth.Elmish.Debouncer.1.0.0/Debouncer.fs.js";
import { FormState_Form$2$reflection } from "../Common/FormState.js";

export class changeUserStatus extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Need", "NotNeed"];
    }
}

export function changeUserStatus$reflection() {
    return union_type("Pages.Customers.Model.changeUserStatus", [], changeUserStatus, () => [[["Item1", Customer$reflection()], ["Item2", enum_type("Shared.UserStatus", int32_type, [["WaitingForEmailVerification", 0], ["Active", 1], ["Blocked", 2], ["Deleted", 3], ["WaitingForSmsVerification", 4]])]], []]);
}

export class Field extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["FirstName", "LastName", "CompanyName", "Email", "PhoneNumber", "Country", "City", "PostCode", "AddressLine1", "AddressLine2", "Vat", "Password"];
    }
}

export function Field$reflection() {
    return union_type("Pages.Customers.Model.Field", [], Field, () => [[], [], [], [], [], [], [], [], [], [], [], []]);
}

export class CustomerData extends Record {
    constructor(Id, Customer, Loading) {
        super();
        this.Id = (Id | 0);
        this.Customer = Customer;
        this.Loading = Loading;
    }
}

export function CustomerData$reflection() {
    return record_type("Pages.Customers.Model.CustomerData", [], CustomerData, () => [["Id", int32_type], ["Customer", Customer$reflection()], ["Loading", bool_type]]);
}

export class State extends Record {
    constructor(Customers, Paging, Sorting, Loading, Error$, Filter, StatusFilter, Debouncer, NewCustomerForm, ConfirmChangeUserStatus, ShowNewCustomerForm, CountryList, ShowDeleted, ShowOverdue, ShowAcceptingConfirmation, Exporting) {
        super();
        this.Customers = Customers;
        this.Paging = Paging;
        this.Sorting = Sorting;
        this.Loading = Loading;
        this.Error = Error$;
        this.Filter = Filter;
        this.StatusFilter = StatusFilter;
        this.Debouncer = Debouncer;
        this.NewCustomerForm = NewCustomerForm;
        this.ConfirmChangeUserStatus = ConfirmChangeUserStatus;
        this.ShowNewCustomerForm = ShowNewCustomerForm;
        this.CountryList = CountryList;
        this.ShowDeleted = ShowDeleted;
        this.ShowOverdue = ShowOverdue;
        this.ShowAcceptingConfirmation = ShowAcceptingConfirmation;
        this.Exporting = Exporting;
    }
}

export function State$reflection() {
    return record_type("Pages.Customers.Model.State", [], State, () => [["Customers", list_type(CustomerData$reflection())], ["Paging", option_type(Types_State$reflection())], ["Sorting", Types_State$1$reflection(CustomerSortFields$reflection())], ["Loading", bool_type], ["Error", string_type], ["Filter", string_type], ["StatusFilter", option_type(CustomerStatus$reflection())], ["Debouncer", State$reflection_1()], ["NewCustomerForm", FormState_Form$2$reflection(NewCustomerFormState$reflection(), Field$reflection())], ["ConfirmChangeUserStatus", changeUserStatus$reflection()], ["ShowNewCustomerForm", bool_type], ["CountryList", list_type(Country$reflection())], ["ShowDeleted", bool_type], ["ShowOverdue", bool_type], ["ShowAcceptingConfirmation", option_type(tuple_type(CustomerData$reflection(), list_type(int32_type), AcceptCustomerType$reflection()))], ["Exporting", bool_type]]);
}

export class Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["LoadSuccess", "TryAcceptCustomer", "NeedAcceptingConfirmation", "AcceptCustomer", "AcceptCustomerSilently", "DeclineCustomer", "CustomerStatusChanged", "PagingMsg", "SortingMsg", "FilterChanged", "StatusFilterChanged", "DebouncerSelfMsg", "EndOfInputSearch", "FirstNameChange", "LastNameChange", "CompanyNameChange", "EmailChange", "PhoneNumberChange", "CountryChange", "CityChange", "PostCodeChange", "AddressLine1Change", "AddressLine2Change", "VatChange", "PasswordChange", "OpenNewCustomerDialog", "CancelNewCustomerDialog", "SaveNewCustomer", "AskChangeUserStatusConfirmation", "CancelChangeUserStatusConfirmation", "CancelAcceptingConfirmation", "ChangeUserStatus", "UserStatusChanged", "CountryListLoaded", "NewCustomerCreated", "NewCustomerNotCreated", "Failed", "ToggleShowDeleted", "ToggleShowOverDue", "ExportClick", "ExportSuccess", "ExportError"];
    }
}

export function Msg$reflection() {
    return union_type("Pages.Customers.Model.Msg", [], Msg, () => [[["Item", PagingResult$1$reflection(Customer$reflection())]], [["Item1", CustomerData$reflection()], ["Item2", AcceptCustomerType$reflection()]], [["Item1", CustomerData$reflection()], ["Item2", list_type(int32_type)], ["Item3", AcceptCustomerType$reflection()]], [["Item", CustomerData$reflection()]], [["Item", CustomerData$reflection()]], [["Item", CustomerData$reflection()]], [["Item1", CustomerData$reflection()], ["Item2", CustomerStatus$reflection()]], [["Item", Types_Msg$reflection()]], [["Item", Types_Msg$1$reflection(CustomerSortFields$reflection())]], [["Item", string_type]], [["Item", option_type(CustomerStatus$reflection())]], [["Item", SelfMessage$1$reflection(Msg$reflection())]], [], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [], [], [], [["Item1", Customer$reflection()], ["Item2", enum_type("Shared.UserStatus", int32_type, [["WaitingForEmailVerification", 0], ["Active", 1], ["Blocked", 2], ["Deleted", 3], ["WaitingForSmsVerification", 4]])]], [], [], [["Item", changeUserStatus$reflection()]], [], [["Item", list_type(Country$reflection())]], [["Item", int32_type]], [], [], [["Item", bool_type]], [], [], [["Item", array_type(uint8_type)]], [["Item", class_type("System.Exception")]]]);
}

